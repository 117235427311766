import React from "react";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Domain_Name } from "../constant";
import { motion } from "framer-motion";

export default function Home(props) {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{props.homeKey.title}</title>
          <meta name="description" content={props.homeKey.description} />
          <meta name="keywords" content={props.homeKey.keywords} />
          <link rel="icon" href="/favicon.ico" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="Seagram's 7" />
          <meta name="twitter:title" content={props.homeKey.title} />
          <meta name="twitter:description" content={props.homeKey.description} />
          <meta property="og:title" content={props.homeKey.title} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={props.homeKey.canonicalTag} />
          <meta property="og:image" content={`${Domain_Name + "/" + props.homeKey.primaryImage}`} />
          <meta property="og:description" content={props.homeKey.description} />
          <link rel="canonical" href={props.homeKey.canonicalTag} />
        </Helmet>
        <motion.div 
        initial={{ opacity:0}}
        animate={{ opacity:1}}
        transition={{
          duration: 0.6,
          ease: [0, 0.71, 0.2, 1.01]
        }}
        exit={{ opacity:0}}
      >
          <Container fluid style={{backgroundColor: '#FBF7F3'}}>
            <Row className="banner">
              <Col>
                <Container>
                  <Row>
                    <Col xs={6} md={{ offset: 1 }} sm={5} lg={4}>
                      <h1 className="lead">An American Icon</h1>
                      <p>
                        A light smooth American Blended whiskey that is easy to mix,
                        easy to serve, and easy to call - especially as a shot and
                        in the signature drink, the 7 & 7.
                      </p>
                      <div>
                        <Link
                          className="btn btn-lg"
                          role="button"
                          to="/whiskeys/seagrams-blended-whiskey"
                        >
                          Learn More <Image src="../images/arrow.svg" alt="arrow" />
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>

            <Row className="find-it">
              <Col>
                <h2 className='text-white'>Find it Near You</h2>
                <p className='text-white'>Easy to mix, Easy to serve and Easy to call</p>
                <Link
                  className="btn btn-black"
                  role="button"
                  to="/where-to-buy-seagrams"
                >
                  Where to Buy <Image src="../images/arrow-black.svg" alt="arrow" />
                </Link>
              </Col>
            </Row>
            <div style={{maxWidth: '1520px', margin: 'auto'}}>
              <Row className="seagram-drinks">
                <Col xs={12}>
                  <h1 className="lead text-center">Our Whiskeys</h1>
                </Col>
                <Col lg={4} md={12} className="blended-whiskey">
                  <div className="d-flex blend-box">
                    <Image
                      src="/images/flavor/seagrams-blended-whiskey.png"
                      alt="blended" className="img-fluid"
                    />
                    <div className="wiskey-desc">
                      <h3>Seagram's 7 Crown Blended Whiskey </h3>
                      <p>
                        A light smooth American Blended whiskey that is easy to mix, easy
                        to serve, and easy to call - especially as a shot and in the
                        signature drink, the 7 & 7.
                      </p>
                      <Link to="/whiskeys/seagrams-blended-whiskey">
                        LEARN MORE <Image src="../images/arrow.svg" alt="arrow" />
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={12} className="honey-whiskey">
                  <div className="d-flex honey-box">
                    <Image
                      src="/images/flavor/seagrams-honey-whiskey.png"
                      alt="honey" className="img-fluid"
                    />
                    <div className="wiskey-desc">
                      <h3>Seagram's 7 Crown Dark Honey</h3>
                      <p>
                        Exceptionally smooth American blended whiskey with real honey and
                        natural flavors.
                      </p>
                      <Link to="/whiskeys/seagrams-blended-whiskey">
                        LEARN MORE <Image src="../images/arrow.svg" alt="arrow" />
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={12} className="apple-whiskey">
                  <div className="d-flex apple-box">
                    <Image
                      src="/images/flavor/seagrams-apple-whiskey.png"
                      alt="apple" className="img-fluid"
                    />
                    <div className="wiskey-desc">
                      <h3>Seagram's 7 Crown Apple</h3>
                      <p>
                        Made with aged bourbon with hints of Apples. Sweet and ripe fresh
                        picked apple notes.
                      </p>
                      <Link to="/whiskeys/seagrams-blended-whiskey">
                        LEARN MORE <Image src="../images/arrow.svg" alt="arrow" />
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="text-center">
                    <Link
                      className="btn btn-primary explore-btn"
                      role="button"
                       to="/whiskeys/seagrams-blended-whiskey"
                    >
                      Explore All Whiskeys{" "}
                      <Image src="../images/arrow.svg" alt="arrow" className="p-0" />
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </motion.div>
      </HelmetProvider>
    </>
  );
}
